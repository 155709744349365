import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as SearchIcon } from "../../assets/Icons/search.svg";
import { ReactComponent as CloseIcon } from "../../assets/Icons/closeFilled.svg";
import { ReactComponent as MenuIcon } from "../../assets/Icons/MenuIcon.svg";
import { ReactComponent as CircleArrow } from "../../assets/Icons/filledCircleArrow.svg";
import { ReactComponent as SignOutIcon } from "../../assets/Icons/signOut.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUser } from "../../Redux/UserSlice/UserSlice";
import { getAccessToken } from "../../Redux/AToken/ATokenSlice";
import {
  appInfo,
  authenticate2,
  getUserSubscription,
} from "../../network/service";
import * as service from "./service";
import { getUserSubscriptionData } from "../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import { getLanguageModal } from "../../Redux/LanguageModal/languageModalSlice";
import { getBannerLoading } from "../../Redux/BannerLoading/bannerLoadingSlice";
const Header = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const userDetails = useSelector((state) => state?.userDetails?.value);
  const selectedLanguage = useSelector((state) => state?.languageModal?.value?.selectedLanguage);
  const [inputValue, setInputValue] = useState("");
  const [searchResults, setSearchResults] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [menuItems, setMenuItems] = useState();
  const [browseHover, setBrowseHover] = useState("");
  const [browseMoreMediumDevice, setBrowseMoreMediumDevice] = useState(false);
  const [position, setPosition] = useState(window.pageYOffset);
  const [headerVisible, setHeaderVisible] = useState(true);
  const [userInfo, setUserInfo] = useState(false);
  const [previousLocation, setPreviousLocation] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchRef = useRef(null);
  const searchRefContainer = useRef(null);
  const searchRefSmall = useRef(null);
  const searchRefSmallContainer = useRef(null);

  const browseRef = useRef(null);
  const userInfoRef = useRef(null);

  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  useEffect(() => {
    const handleScroll = () => {
      let moving = window.pageYOffset;
      // setHeaderVisible(position > moving);
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  useEffect(() => {
    if (searchActive) {
      // searchRef?.current.focus();
      searchRefSmall?.current?.focus();
      searchRefSmallContainer?.current?.focus();
      setPreviousLocation(location);
    }
  }, [searchActive]);

  // useEffect(() => {
  //   if (accessToken) {
  //     fetchUserSubscriptionDetails();
  //   }
  // }, [user, accessToken]);

  useEffect(() => {
    fetchMenuItems();
  }, [user]);

  useEffect(() => {
    let handleOutClick = (e) => {
      if (
        !searchRefContainer?.current?.contains(e.target) &&
        !searchRefSmallContainer?.current?.contains(e.target)&&
        !browseRef?.current?.contains(e.target) &&
        !userInfoRef?.current?.contains(e.target)
      ) {
        setInputValue("");
        setSearchActive(false); //outside click
        setBrowseHover(null);
        setUserInfo(false);
      
      }
    };

    window.addEventListener("click", handleOutClick);
    return () => {
      window.addEventListener("click", handleOutClick);
    };
  }, [searchRefContainer, searchRefSmallContainer ,browseRef , userInfoRef]);
  // useEffect(() => {
  //   searchResultsShow();
  // }, [searchResults]);

  const fetchMenuItems = async () => {
    try {
      const menuResponse = await service.getMenuItems(appInfo);
      if (menuResponse?.status === 200) {
        setMenuItems(menuResponse?.data?.data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "top-center",
      });
    }
  };

  const fetchUserSubscriptionDetails = async () => {
    const userSubResponse = await getUserSubscription(appInfo);
    if (userSubResponse?.status === 200) {
      dispatch(
        getUserSubscriptionData({
          userSubscription: userSubResponse?.data,
        })
      );
    }
  };

  /// Search functionality  start
  const searchSubmit = (value) => {
    if (value) {
      setInputValue("");
      setSearchActive(false);
      navigate(`/search?query=${value}`);
    } else {
      navigate(previousLocation?.pathname, { state: previousLocation?.state });
      setInputValue("");
    }
  };
  const searchSubmitBrowseMore = (value) => {
    setSearchActive(true);
    if (value) {
      setInputValue("");
      navigate(`/search?query=${value}`);
      setBrowseMoreMediumDevice(null);
    } else {
      navigate(previousLocation?.pathname, { state: previousLocation?.state });
      setInputValue("");
    }
    // if (!searchActive) {
    //   setSearchActive(true);
    // } else {
    //   setSearchActive(false);
    //   setBrowseMoreMediumDevice(null);
    // }
  };

  /// end

  const logoutHandler = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("selectedSubId");
    localStorage.removeItem("deviceType");
    localStorage.removeItem("selectedAmount");
    localStorage.removeItem("vanityUrl");
    tokenAuthenticate();

    // window.FB.logout(function (response) {
    //   // user is now logged out
    // });
  };

  const tokenAuthenticate = async () => {
    const response = await authenticate2(appInfo);
    if (response?.status === 200) {
      dispatch(
        getAccessToken({
          accessToken: response?.data?.token,
        })
      );
      navigate("/home");

      setTimeout(() => {
        dispatch(
          getBannerLoading({
            bannerLoading: false
          })
        )
        dispatch(
          getUser({
            user: null,
          })
        );
      }, 300);
    }
  };

  // const searchResultsShow = (value) => {
  //   setSearchResults(true);
  //   if (!searchActive) {
  //     setSearchActive(true);
  //   } else {
  //     setSearchActive(false);
  //     setBrowseMoreMediumDevice(null);
  //   }
  // };

  const languageHandler = () => {
    let data = {
      isOpen: true,
      selectedLanguage: selectedLanguage ? selectedLanguage : null
    }
    dispatch(getLanguageModal({
      languageModal: data
    }))
  }

  const menuNavigateHandler = (data) => {
    setBrowseHover(null)
    setBrowseMoreMediumDevice(false)
    if (data?.type === "LINK") {
      window.open(data?.link, "_self")
    } else {
      navigate(`/category/${data?.key}`, { state: { careers: data?.key } })
    }
  }

  return (
    <>
      <ToastContainer />
      <div
        className={browseMoreMediumDevice ? "active overlayOne" : "overlayOne"}
      ></div>
      <div
        className={
          position === 0 ? "header-container" : "header-container background"
        }
      >
        <div className="wrapper">
          <div className="leftContainer">
            <div className="menuIconContainer">
              <MenuIcon onClick={() => setBrowseMoreMediumDevice(true)} />
            </div>
            <div className="logoContainer">
              <Link to="/home">
                <h1>
                  <img
                    src={projectInfo?.projectConfig?.config?.LOGO}
                    alt="Logo"
                  />
                </h1>
              </Link>
            </div>
            <ul className={searchActive ? "hide browse" : "browse"}>
              <li
                className={browseHover === "show" ? "active" : "no-active"}
             
                ref={browseRef}
                onClick={() => {
                  setBrowseMoreMediumDevice(true);
                  setBrowseHover("show");
                }}
                
                
                // onMouseLeave={() => setBrowseHover(null)}
              >
                <span>Browse</span>
              </li>
              {
                projectInfo?.projectConfig?.config?.LIVE_REQUIRED === "true" &&
                <li className={location?.pathname === "/live-player" ? "active" : ""} onClick={() => navigate("/live-player")}>
                  <span>Live TV</span>
                </li>
              }
              <li className="other-links" onClick={() => { window.open('https://redeemtv.com/donate', '_blank') }}>
                <span>Donate</span>
              </li>
              <li className="other-links" onClick={() => navigate("/my-list")}>
                <span>My List</span>
              </li>
              <li className="other-links" onClick={() => { window.open('https://christianhistoryinstitute.org/today', '_blank') }}>
                <span>Today!</span>
              </li>
              {/* <li className={location?.pathname === "/category/additional_donor_content" ? "active" : "other-links"} onClick={() => navigate("/category/additional_donor_content")}>
                <span>Additional Donor Content</span>
              </li> */}

              {/* <li className={location?.pathname === "/category/additional_donor_content" ? "active" : "other-links"} onClick={() => navigate("/category/additional_donor_content")}>
                <span>How to Update</span>
              </li> */}
            </ul>
          </div>
          <div
            className={
              searchActive ? "rightContainer increase" : "rightContainer"
            }
          >
            <div
              className={
                searchActive ? "inputContainer showInput" : "inputContainer"
              }
              ref={searchRefContainer}
              onClick={() => {
                setSearchActive(true);
                searchRef?.current.focus();
              }}
            >
              {searchActive ? (
                <SearchIcon
                  onClick={
                    () => searchSubmit(inputValue)
                    // setSearchActive(!searchActive)
                  }
                />
              ) : (
                <SearchIcon
                  onClick={() => {
                    searchRef?.current.focus();
                    setSearchActive(true);
                  }}
                />
              )}

              <input
                ref={searchRef}
                onClick={() => setSearchActive(true)}
                type="text"
                value={inputValue}
                // placeholder={searchActive && "Find Movies, TV shows and more"}
                placeholder={
                  searchActive
                    ? "Find Movies, TV shows and more"
                    : "Find Movies, TV shows and more"
                }
                onChange={(e) => {
                  // searchHandleChange(e?.target?.value);
                  setInputValue(e?.target?.value);
                }}
                onKeyDown={(e) =>
                  e.key === "Enter" ? searchSubmit(inputValue) : ""
                }
              />
            </div>
            {/* <i class="fa-regular fa-globe"></i>
            <div className="language" onClick={()=>languageHandler()}>
              <i class="fa-solid fa-globe"></i>
              <span>{selectedLanguage!==null?selectedLanguage:"Language"}</span>

            </div> */}
            {user ? (
              <div
                className={userInfo ? "userInfo active" : "userInfo"}
                ref={userInfoRef}
                onClick={() => setUserInfo(true)}
                //onMouseLeave={() => setUserInfo(false)}
              >
                <h4 className={userInfo ? "active" : ""}>Hi, {userDetails?.first_name}</h4>
                <CircleArrow />
              </div>
            ) : (
              <div className="buttonContainer">
                <button
                  className="register"
                  onClick={() => navigate("/register")}
                >
                  Sign up
                </button>
                <button className="signIn" onClick={() => navigate("/login")}>
                  Log in
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={
          userInfo && headerVisible
            ? "userInfoContainer active"
            : "userInfoContainer"
        }
  
        // onMouseOver={() => setUserInfo(true)}
        // onMouseLeave={() => setUserInfo(false)}
        onClick={()=>setUserInfo(!userInfo)}
      >
        <div className="top">
          <ul>
            <Link to="/account">
              <li>
                <span>Account Settings</span>
              </li>
            </Link>
            <Link to="/my-list">
              <li>
                <span>My List</span>
              </li>
            </Link>
            <Link to="/tv">
              <li>
                <span>Link TV App</span>
              </li>
            </Link>
          </ul>
        </div>
        <div className="bottom">
          <div className="signOutContainer" onClick={() => logoutHandler()}>
            <SignOutIcon />
            <span>Sign Out</span>
          </div>
        </div>
      </div>

      <div
        className={browseHover === "show" ? "browseMore active" : "browseMore"}
      >
        <div className="overlay"></div>

        <div
          className="browseMoreContainer"
          onMouseOver={() => setBrowseHover("show")}
          // onMouseLeave={() => setBrowseHover(null)}
          onClick={() => setBrowseHover(null)}
          
        >
          {menuItems?.map((item, index) => (
            <div className="left" key={index}>
              <h1 className="categoryName">{item?.key}</h1>
              <ul>
                {item?.items?.map((itemShow, keyIndex) => (
                  <li key={keyIndex} onClick={() => menuNavigateHandler(itemShow)}>

                    <span> {itemShow?.value}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      <div className="browseMoreResponsive">
        <div
          className={browseMoreMediumDevice ? "container active" : "container"}
        >
          <div className="head large">
            <div className="left">
              <h1>Browse</h1>
            </div>
            <div className="right">
              <CloseIcon onClick={() => setBrowseMoreMediumDevice(false)} />
            </div>
          </div>
          <div className="head small">
            <div className="left">
              <CloseIcon onClick={() => setBrowseMoreMediumDevice(false)} />
              <span>Close</span>
            </div>
            <div className={searchActive ? "right increase" : "right"}>
              <div
                className={
                  searchActive ? "inputContainer showInput" : "inputContainer"
                }
                ref={searchRefSmallContainer}
              >
                <SearchIcon
                  onClick={() => {
                    // searchResultsShow();
                    searchSubmitBrowseMore(inputValue);
                  }}
                />

                <input
                  ref={searchRefSmall}
                  onClick={() => {
                    setSearchActive(true);
                  }}
                  type="text"
                  value={inputValue}
                  placeholder={
                    !searchActive && "Find Movies, TV shows and more"
                  }
                  onChange={(e) => {
                    // searchHandleChange(e?.target?.value);
                    setInputValue(e?.target?.value);
                  }}
                  onKeyDown={(e) =>
                    e.key === "Enter" ? searchSubmitBrowseMore(inputValue) : ""
                  }
                />
              </div>
            </div>
          </div>

          {/* <div className="language" onClick={()=>languageHandler()}>
              <i class="fa-solid fa-globe"></i>
              <span>{selectedLanguage!==null?selectedLanguage:"Language"}</span>
            </div> */}
          {/* <div className="top">
              <h1 className="categoryName">Links</h1>
              <ul>
                {
                  projectInfo?.projectConfig?.config?.LIVE_REQUIRED ==="true"&&
                  <li onClick={()=>{navigate("/player");setBrowseMoreMediumDevice(false)}}><span>Live</span></li>
                }
              </ul>
            </div> */}
          {menuItems?.map((item, index) => (
            <div className="top" key={index}>
              <h1 className="categoryName">{item?.key}</h1>
              <ul>
                {item?.items?.map((items, i) => (
                  <li key={i} onClick={() => menuNavigateHandler(items)}>
                    <span> {items?.value}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Header;
